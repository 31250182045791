import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "jspdf-autotable"
import {useAsyncDebounce} from "react-table";

export default function SearchPagination({meta, setMeta, policiesLength, totalPages}) {
  const { current_page, next_page, prev_page } = meta
  const [value, setValue] = useState(current_page)
  const onChange = useAsyncDebounce(value => {
    setMeta({...meta, current_page: value})
  }, 1000)
  const showPagination = policiesLength !== 0

  const paginationHtml = () => {
    return (
      <div className="pagination pagination-sm pagination-wrapper justify-content-start">
        <div className="page-item">
          <button
            className="btn btn-success btn-sm"
            onClick={() => setMeta({...meta, current_page: current_page - 1})}
            disabled={prev_page === current_page}
          >
            {"<<"}
          </button>
        </div>
        <div className="page-item" style={{minWidth: '120px', margin: '0'}}>
          <div className="page-item--control">
            <span className="" >Page </span>
            <input
              className="form-control"
              value={value || ""}
              onChange={e => {
                setValue(e.target.value)
                onChange(e.target.value);
              }}
            />
            <span className="">of {totalPages}</span>
          </div>
        </div>
        <div className="page-item">
          <button
            className="btn btn-success btn-sm"
            onClick={() => setMeta({...meta, current_page: current_page + 1})}
            disabled={next_page === current_page}
          >
            {">>"}
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      {showPagination ? paginationHtml() : ''}
    </>
  )
}
