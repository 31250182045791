import React from "react"
import styles from './styles.module.scss'


export default function Loader() {
  return (
    <div className={styles.modal_loader}>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  )
}
