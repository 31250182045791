import React from "react"

const AlarmIcon = () => (

  <svg className="svg-icon" style={{
    width: '1.5em',
    height: '1em',
    verticalAlign: 'middle',
    overflow: 'hidden',
    fontSize: '16px',
    fill: 'red'
  }}
       viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M542.165333 584.832A42.538667 42.538667 0 0 1 512 597.333333h-149.333333a42.666667 42.666667 0 1 1 0-85.333333h106.666666v-170.666667a42.666667 42.666667 0 1 1 85.333334 0v213.333334c0 11.776-4.778667 22.442667-12.501334 30.165333z m-423.04-196.821333A192 192 0 1 1 402.901333 142.08 427.306667 427.306667 0 0 1 512 128c37.717333 0 74.261333 4.906667 109.098667 14.08a192 192 0 1 1 283.797333 245.930667C926.656 439.210667 938.666667 495.530667 938.666667 554.666667c0 91.52-28.8 176.32-77.866667 245.802666l108.032 108.032a42.666667 42.666667 0 0 1-60.330667 60.330667l-103.765333-103.765333A425.194667 425.194667 0 0 1 512 981.333333a425.194667 425.194667 0 0 1-292.736-116.266666l-103.765333 103.765333a42.666667 42.666667 0 0 1-60.330667-60.330667l108.032-108.032A424.704 424.704 0 0 1 85.333333 554.666667c0-59.136 12.032-115.456 33.770667-166.656zM161.066667 311.893333a428.586667 428.586667 0 0 1 160.448-139.136A106.666667 106.666667 0 1 0 161.066667 311.893333z m541.376-139.136a428.586667 428.586667 0 0 1 160.448 139.136 106.666667 106.666667 0 1 0-160.448-139.136zM512 896c188.522667 0 341.333333-152.810667 341.333333-341.333333s-152.810667-341.333333-341.333333-341.333334S170.666667 366.144 170.666667 554.666667s152.810667 341.333333 341.333333 341.333333z"/>
  </svg>
)

export default AlarmIcon
