import { combineReducers, configureStore } from '@reduxjs/toolkit'
import searchSliceReducer from './slices/searchSlice'

const reducer = combineReducers({
  search: searchSliceReducer
});

const store = configureStore({
  reducer,
})

export default store
