import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const substringHelper = (string, maxLength, withTooltip = false) => {
  const handleTooltip = (props) => <Tooltip {...props}>{string}</Tooltip>

  if (withTooltip && string?.length > maxLength) {
    return (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={handleTooltip}
      >
        <span>{`${string?.substring(0, maxLength - 4)} ...`}</span>
      </OverlayTrigger>
    )
  } else {
    return (
      string?.length < maxLength ? string : `${string?.substring(0, maxLength - 4)} ...`
    )
  }
}
