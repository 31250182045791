import React, { useState, useEffect, useMemo } from 'react'
import {useTable} from 'react-table'
import 'bootstrap/dist/css/bootstrap.min.css';
import BTable from 'react-bootstrap/Table';
import IconModal from './utils/IconModal/IconModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMagnifyingGlass, faXmark, faSpinner} from '@fortawesome/free-solid-svg-icons'
import styles from './PayerResourceTable.module.scss'
import ReactTooltip from "react-tooltip";

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <BTable striped bordered hover size="sm" {...getTableProps()}>
      <thead>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps({
              style: { width: column.width }
            })}>{column.render('Header')}</th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map((row, i) => {
        prepareRow(row)
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map(cell => {
              return <td {...cell.getCellProps({
                style: {
                  width: cell.column.width,
                }
              })}>{cell.render('Cell')}</td>
            })}
          </tr>
        )
      })}
      </tbody>
    </BTable>
  )
}

export default function PayerResourcesTable({ documents, categories, statistics }) {

  const headers = {
    pre_authorization: 'Pre Authorization',
    medical_policy_and_pharmacy: 'Medical Policy And Pharmacy',
    fee_schedules_and_reimbursement: 'Fee Schedules and Reimbursement',
    provider_manuals: 'Provider Manuals',
    provider_updates: 'Provider Updates'
  }

  const [columns, setColumns] = useState([
    {
      Header: 'Payer Resources',
      accessor: 'title',
      width: 140
    },
    {
      Header: 'Total policies #',
      width: 150,
      Cell: ({ row }) => {
        let currentPolicies = statistics.find(el => el.id === row.original.id)

        if (currentPolicies && currentPolicies.last_success === null && currentPolicies.last.parsing_end_time === null)
          return (
            <div className={styles.totalWrap}>
              <span style={{marginRight: '30px'}} className={styles.loadWrapper} data-tip data-for={`loadLastExecution-${row.original.id}`}>
                <FontAwesomeIcon className="fa-spin" icon={faSpinner} color="blue" />
              </span>
              <ReactTooltip id={`loadLastExecution-${row.original.id}`} place="top" effect="solid">
                Payer resources are being processed
              </ReactTooltip>
            </div>
          )

        if (currentPolicies === undefined || currentPolicies.last_success === null)
          return (
            <div className={styles.totalWrap}>
              <div></div>
              <div><div className={styles.errorWrapper}><FontAwesomeIcon icon={faXmark} color="red" /></div></div>
            </div>
          )

        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const successDate = new Date(currentPolicies.last_success.parsing_start_time).toLocaleDateString("en-US", options)
        const failedDate = new Date(currentPolicies.last.parsing_start_time).toLocaleDateString("en-US", options)

        if (currentPolicies.prev === null)
          return (
            <div className={styles.totalWrap}>
              <div data-tip data-for={`successCount-${row.original.id}`}>
                {currentPolicies.last_success?.success_count}
                <ReactTooltip id={`successCount-${row.original.id}`} place="top" effect="solid">
                  Last successful execution on {successDate}
                </ReactTooltip>
              </div>

              <div>
              </div>
            </div>
          )

        if (!currentPolicies.last.success)
          return (
            <div className={styles.totalWrap}>
              <div data-tip data-for={`successCount-${row.original.id}`}>
                {currentPolicies.last_success?.success_count}
                <ReactTooltip id={`successCount-${row.original.id}`} place="top" effect="solid">
                  Last successful execution on {successDate}
                </ReactTooltip>
              </div>

              {currentPolicies.last.parsing_end_time === null
                ?
                    <div>
                      <span className={styles.loadWrapper} data-tip data-for={`loadLastExecution-${row.original.id}`}>
                        <FontAwesomeIcon className="fa-spin" icon={faSpinner} color="blue" />
                      </span>
                      <ReactTooltip id={`loadLastExecution-${row.original.id}`} place="top" effect="solid">
                        Payer resources are being processed
                      </ReactTooltip>
                    </div>
                :
                  <div>
                    <span className={styles.errorWrapper} data-tip data-for={`errorLastExecution-${row.original.id}`}>
                      <FontAwesomeIcon icon={faXmark} color="red" />
                    </span>
                    <ReactTooltip id={`errorLastExecution-${row.original.id}`} place="top" effect="solid">
                      Last execution on {failedDate} failed
                    </ReactTooltip>
                  </div>
              }
            </div>
          )

        let diff = currentPolicies.last_success?.success_count - currentPolicies.prev?.success_count
        let percent = diff/currentPolicies.prev?.success_count * 100.0
        let typeData = diff < 0 ? { color: 'red', icon: '➘', text: 'decreased' }
                                : (diff > 0 ? { color: 'green', icon: "➚", text: 'increased' }
                                            : { color: 'green', icon: "➚", text: 'not changed'} )

        return (
          <div className={styles.totalWrap}>
            <div data-tip data-for={`successCount-${row.original.id}`}>
              {currentPolicies.last_success?.success_count}
              <ReactTooltip id={`successCount-${row.original.id}`} place="top" effect="solid">
                Last successful execution on {successDate}
              </ReactTooltip>
            </div>
            <div>
              <span style={{color: typeData.color, fontSize: '.8em'}} data-tip data-for={`successStats-${row.original.id}`}>
                 {typeData.icon} {diff} { isNaN(percent) ? '' : `(${+percent.toFixed(2)}%)` }
              </span>
              <ReactTooltip id={`successStats-${row.original.id}`} place="top" effect="solid">
                Number of policies {typeData.text} comparing to the previous execution
              </ReactTooltip>
            </div>
          </div>
        )
      }
    },
    {
      Header: 'Health*',
      width: 74,
      Cell: ({ row }) => {
        let currentPolicies = statistics.find(el => el.id === row.original.id)
        if ( !currentPolicies?.last?.success || currentPolicies === undefined )
          return <p></p>

        let diff = currentPolicies.last.success_count - currentPolicies.average
        let percent = 50 + (Math.floor(diff/currentPolicies.average * 100.0))

        if(percent > 85)
          percent = 85
        else if(percent < 20)
          percent = 20

        let color = parseInt(percent) > 40 ? 'green' : 'red'

        return (
          <div className={styles.averageBase}>
            <span className={styles.Line} data-tip data-for={`line-${row.original.id}`}>
              <ReactTooltip id={`line-${row.original.id}`} place="top" effect="solid">
                Average - {currentPolicies.average}
              </ReactTooltip>
            </span>

            <span
              data-tip data-for={`circle-${row.original.id}`}
              className={styles.Circle}
              style={{background: color, left: percent + "%"}}
            >
              <ReactTooltip id={`circle-${row.original.id}`} place="top" effect="solid">
                Last success count - {currentPolicies.last.success_count}
              </ReactTooltip>
            </span>

          </div>
        )
      }
    }
  ])
  useEffect(() => {
    categories.map( item => {
      setColumns(prevState => [...prevState,
        {
          Header: headers[item],
          accessor: item,
          width: 130,
          Cell: ({ row }) =>
            <IconModal
              title={row.original.title}
              documents={row.original.groups_documents[item]}
              category={headers[item]}
            />
        }]
      )} )
  }, [categories])

  return (
    <div className="row">
      <h2>Payer Resources</h2>
      <div id='table' className="col-xs-12 col-md-12">
        <span>*Result of the last execution in comparison to the average number of policies parsed for the Payer over the last 6 months.</span>
        <Table columns={columns} data={documents} />
      </div>
    </div>
  )
}
