import axios from 'axios'

const routes = {
  api: {
    policySearch: () => '/',
    policyVersion: () => '/policy_versions',
    savedSearches: () => '/saved_searches',
    createSavedSearch: () => `/saved_searches/`,
    deleteSavedSearch: ({id}) => `/saved_searches/${id}`,
    updateSavedSearch: ({id}) => `/saved_searches/${id}`
  }
}

export default routes

const csrf = document.querySelector("meta[name='csrf-token']")?.getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = csrf
