import React from 'react'
import ReactModal from 'react-modal'

export default function FilterModal({showModal, closeCallback}) {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={showModal}
      className={'modal-dialog'}
      onRequestClose={() => {closeCallback(false)}}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
    >
      <div className='modal-content'>
        <div className='modal-body'>
          Please, input any of search form's fill
        </div>
        <div className='modal-footer'>
          <button onClick={() => {closeCallback(false)}}>Ok</button>
        </div>
      </div>
    </ReactModal>
  );
}
