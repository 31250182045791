import React, { useState, useMemo} from "react";
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useExpanded } from 'react-table'
import 'bootstrap/dist/css/bootstrap.min.css';
import BTable from 'react-bootstrap/Table';
import ModalLinks from '../ModalLinks/ModalLinks'
import { useExportData } from "react-table-plugins";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable"
import { Dropdown } from 'react-bootstrap'
import _ from "lodash"
import SearchPagination from './SearchPagination'
import AlarmIcon from "../../utils/Icons/AlarmIcon";
import ReactTooltip from "react-tooltip";
import {faArrowUpRightFromSquare, faCaretUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from 'moment';
moment.updateLocale(moment.locale(), { invalidDate: "" })

export default function SearchResult({policies, meta, setMeta, tableFilterValue, setTableFilterValue, sorting, setSorting}) {
  const { current_page, per_page, total_count, total_pages, total_with_collapse } = meta
  const { attribute, direction } = sorting
  const columns = useMemo(() => [
    {
      Header: () => null,
      id: "expander",
      width: 50,
      maxWidth: 50,
      Cell: ({ row, rows, toggleRowExpanded }) =>
        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                // We can even use the row.depth property
                // and paddingLeft to indicate the depth
                // of the row
                paddingLeft: `${row.depth * 2}rem`,
              },
              onClick: () => {
                const expandedRow = rows.find((row) => row.isExpanded);

                if (expandedRow) {
                  const isSubItemOfRow = Boolean(
                    expandedRow && row.id.split(".")[0] === expandedRow.id
                  );

                  if (isSubItemOfRow) {
                    const expandedSubItem = expandedRow.subRows.find(
                      (subRow) => subRow.isExpanded
                    );

                    if (expandedSubItem) {
                      const isClickedOnExpandedSubItem =
                        expandedSubItem.id === row.id;
                      if (!isClickedOnExpandedSubItem) {
                        toggleRowExpanded(expandedSubItem.id, false);
                      }
                    }
                  } else {
                    toggleRowExpanded(expandedRow.id, false);
                  }
                }
                row.toggleRowExpanded();
              },
            })}
          >
            {row.isExpanded ? <FontAwesomeIcon icon={faCaretUp} style={{marginLeft: '12px', fontSize: 'x-large'}} color='#198754' /> : <span className="policy_icon">{row.original.subRows.length}</span>}
            </span>
        ) : null,
    },
    {
      Header: "Payer",
      accessor: 'company_name',
      maxWidth: 110,
      minWidth: 110,
      width: 110,
      sortable: true
    },
    {
      Header: "State",
      accessor: 'nation_state_name',
      maxWidth: 110,
      minWidth: 110,
      width: 110,
      sortable: true,
      Cell: ({row}) => {
        return(<span>{row.original.nation_state_name}</span>)
      }
    },
    {
      Header: "Title",
      accessor: 'original_title',
      sortable: true,
      Cell: ({row}) => {
        const titleStyle = {
          color: 'red'
        }
        const link = row.original.archived ? row.original.stored_file_path : row.original.external_link
        return(
          <a style={row.original.no_text ? titleStyle : {}} href={link} target="_blank" >
            {row.original.original_title}
            {row.original.external && <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{marginLeft: '3px'}} color='#198754' />}
          </a>
        )
      }
    },
    {
      Header: "Revision Date",
      accessor: 'revision_date',
      maxWidth: 80,
      minWidth: 80,
      width: 80,
      sortable: true,
      Cell: ({row}) => {
        return(<span>{moment(row.original.revision_date).format('MM-DD-YYYY')}</span>)
      }
    },
    {
      Header: "Next Review",
      accessor: 'next_review_date',
      maxWidth: 80,
      minWidth: 80,
      width: 80,
      sortable: true,
      Cell: ({row}) => {
        let isExpired = Date.parse(new Date()) > Date.parse(row.original.next_review_date)

        return (
          <span
            style={{color: isExpired ? 'red' : 'inherit', display: 'flex', alignItems: 'center'}}>
            {moment(row.original.next_review_date).format('MM-DD-YYYY')}
            {isExpired && (
              <span data-tip data-for={`alarm-${row.original.id}`}>
                <AlarmIcon />

                <ReactTooltip id={`alarm-${row.original.id}`} place="top" effect="solid">
                  It seems that the policy has not been reviewed by the Payer as planned.
                </ReactTooltip>
              </span>
            )}
          </span>
        )
      }
    },
    {
      Header: "Policy Version",
      accessor: 'version',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
      getProps: () => {
        return {
          style: {
            textAlign: 'center'
          },
        };
      },
      Cell: ({row}) => {
        return(
          <ModalLinks
            policyCompanyId={row.original.company_id}
            policyVersion={row.original.version}
            policyTitle={row.original.original_title}
            policyArchived={row.original.archived}
            nationStateId={row.original.nation_state_id}
            htmlOpenClassName="modal_links__overlay"
            ariaHideApp={false}
          />
        )
      }
    },
    {
      Header: "Score",
      accessor: '_score',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
      sortable: true,
      Cell: ({row}) => {
        return(<span>{parseFloat(row.original._score).toFixed(2)}</span>)
      }
    },
    {
      Header: "Pages Count",
      accessor: 'pages_count',
      maxWidth: 50,
      minWidth: 50,
      width: 50,
      sortable: true
    }
  ], [])

  const onHeaderClick = (column, direction) => {
    setSorting({attribute: column.id, direction: direction})
  }
  const prettyHeader = (column) => {
    let ascTriangle = column.id === attribute && direction === 'asc' ? '▲' : '△'
    let descTriangle = column.id === attribute && direction === 'desc' ? '▼' : '▽'
    return(
      <th width={column.width} {...column.getHeaderProps()}>
        <div className="table_pretty_header" >
          <span>{column.render('Header')}</span>
          {column.sortable && <div className="sortable_arrows">
            <span className="sort_arrow" onClick={() => onHeaderClick(column, 'asc')}>{ascTriangle}</span>
            <span className="sort_arrow" onClick={() => onHeaderClick(column, 'desc')}>{descTriangle}</span>
          </div>}
        </div>
      </th>
    )
  }
  const Table = function ({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      preGlobalFilteredRows,
      exportData
    } = useTable({
        columns,
        data,
        getExportFileBlob,
        initialState: {
          pageIndex: current_page - 1, // DEAL WITH IT
          pageSize: per_page,
        }
      },
      useFilters,
      useGlobalFilter,
      usePagination,
      useExportData,
      useExpanded
    )

    // Render the UI for your table
    return (
      <>
        <div className="table_sort">
          <SearchPagination
            meta={meta}
            setMeta={setMeta}
            policiesLength={policies.length}
            totalPages={total_pages}
          />

          <div className="table_sort__filter">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={tableFilterValue}
              setGlobalFilter={setTableFilterValue}
            />
          </div>

          <div className="table_sort__displayed">
            <select
              value={per_page}
              onChange={(e) => {
                setMeta({...meta, per_page: +e.target.value})
              }}
            >
              {[10, 25, 50, 100, 1000].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>

            <Dropdown>
              <Dropdown.Toggle variant="success btn-sm" id="dropdown-basic">
                Export
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => { exportData("xlsx", false); }} >
                  Export Current View as xlsx
                </Dropdown.Item>
                <Dropdown.Item onClick={() => { exportData("pdf", false); }} >
                  Export Current View as PDF
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          </div>
        </div>
        <BTable striped bordered hover size="sm" {...getTableProps()}>
          <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return prettyHeader(column)
              })}
            </tr>
          ))}

          </thead>
          <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr style={{backgroundColor: row.id.includes('.') ? '#ffffb3' : undefined}}{...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
          </tbody>
        </BTable>

        <SearchPagination meta={meta} setMeta={setMeta} policiesLength={policies.length} totalPages={total_pages} />
      </>
    )
  }

  const GlobalFilter = function ({
                                   globalFilter,
                                   setGlobalFilter,
                                 }) {
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 1500)

    return (
      <div className="input-group input-group-sm">
        <span className="input-group-text">
          Filter:{' '}
        </span>
        <input
          className="form-control"
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={total_count ? `${total_count} of ${total_with_collapse} total` : 'No Records'}
        />
      </div>
    )
  }

  const getExportFileBlob = function ({ columns, data, fileType, fileName }) {
    if (fileType === "xlsx") {
      // XLSX example
      const header = columns.map((c) => c.exportValue);
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();

      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });

      const links = compatibleData.map((row) => {
        const policy = _.find(policies, function(item){
          return item.original_title === row.Title
        })
        return {title: policy.original_title, link: policy.external_link}
      })

      for (let i = 1; i < compatibleData.length + 1; i++) {
        ws1[XLSX.utils.encode_cell({
          c: 2,
          r: i
        })].l = { Target: links[i-1].link };
      }
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    //PDF example
    if (fileType === "pdf") {
      const headerNames = columns.map((column) => column.exportValue);
      const doc = new JsPDF();
      const totalPagesExp = '{total_pages_count_string}';

      doc.autoTable({
        head: [headerNames],
        body: data,
        margin: { top: 0, left: 0, right: 0, bottom: 0 },
        padding: { top: 0, bottom: 10, left: 5, right: 5},
        styles: {
          minCellHeight: 15,
          halign: "left",
          valign: "center",
          fontSize: 10,
        },
        willDrawCell: data => {
          if (data.cell.section === 'body' && data.column.index === 2) {
            const link = _.find(policies, function(item){
              return item.original_title === data.cell.raw
            })
            doc.link(
              data.cell.x,
              data.cell.y,
              data.cell.contentWidth,
              data.cell.contentHeight,
              {url: link.external_link}
            )
            doc.setTextColor(13, 110, 253)
          }
        },
        didDrawPage: function (data) {
          // Footer
          var str = 'Page ' + doc.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' of ' + totalPagesExp
          }
          doc.setFontSize(10)

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
          doc.text(str, 5, pageHeight - 3)
        }
      });
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
      }
      doc.save(`${fileName}.pdf`);

      return false;
    }

    // Other formats goes here
    return false;
  }

  return (
      <Table columns={columns} data={policies} />
    )
}
