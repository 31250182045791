import React from 'react'
import DatePicker from "react-datepicker"

export default function RenderDatePicker ({ placeholderText, name, input: { value, onChange } }) {
    function convertUTCToLocalDate(date) {
        if (!date) {
            return date
        }
        date = new Date(date)
        date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
        return date
    }

    function convertLocalToUTCDate(date) {
        if (!date) {
            return date
        }
        date = new Date(date)
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
        return date
    }

    return (
        <DatePicker
            className="form-control mb-3"
            placeholderText={placeholderText}
            dateFormat="MM-dd-yyyy"
            selectsStart
            selected={convertUTCToLocalDate(value)}
            disabledKeyboardNavigation
            name={name}
            onChange={currentValue => onChange(convertLocalToUTCDate(currentValue))}
        />
    );
};
