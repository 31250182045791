import React, {useEffect, useMemo, useRef, useState} from 'react';
import axios from "axios";
import routes from "../../../packs/constants/routes";
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
import ReactModal from 'react-modal'
import BTable from 'react-bootstrap/Table';
import styles from './Styles.module.scss'
import SavedSearchOptions from "./SavedSearchOptions";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {substringHelper} from "../../helper";
import { useDispatch } from 'react-redux'
import {setSearchSlice} from "../../../packs/Reducers/slices/searchSlice";

const SavedSearchModal = ({showModal, setShowModal}) => {
  console.log('Im rendered')
  const dispatch = useDispatch()
  const [savedSearches, setSavedSearches ] = useState([])
  const [editableRowId, setEditableRowId] = useState(null)

  const deleteSavedSearchesCallback = id => setSavedSearches(savedSearches.filter(item => item.id !== id))

  const editSavedSearchesCallback = id => setEditableRowId(id)

  const searchSavedSearchesCallback = id => {
    let formValues = {}
    let currentItem = savedSearches.find(item => item.id === id)
    formValues.value = currentItem.keyword

    Object.entries(currentItem.query).forEach(([key, value]) => {
      if(value.length === 0) {
        return;
      }

      if(key === 'revision_date_from' || key === 'revision_date_to') {
        formValues[key] = new Date(value)
        return;
      }

      formValues[key] = value
    })

    dispatch(setSearchSlice(formValues))
    setShowModal(false)
  }

  const columns = useMemo(() => [
    {
      Header: "Search Name",
      accessor: 'title',
      width: '250',
      Cell: ({row}) => {
        if(row.original.id === editableRowId){
          const inputRef = useRef(null);

          const handleUpdateItem = () => {
            if(inputRef.current.value.trim().length === 0) {
              alert("Title can not be blank!")
            }else {
              setSavedSearches(prevState => prevState.map(
                el => el.id === row.original.id ? { ...el, title: inputRef.current.value } : el
              ))
              setEditableRowId(null)

              const id = row.original.id

              axios.put(
                routes.api.updateSavedSearch({id}),
                {title: inputRef.current.value}
              )
                .catch(error => {
                  console.log('ERROR', error, error.response)
                })
            }
          }

          return (
            <div className={styles.editTitleForm}>
              <input ref={inputRef} type="text" name="title" defaultValue={row.original.title} required />
              <button className={styles.saveBtn} onClick={handleUpdateItem}>Save</button>
              <button className={styles.cancelBtn} onClick={() => setEditableRowId(null)}>Cancel</button>
            </div>
          )
        }else{
          return substringHelper(row.original.title, 50, true)
        }
      }
    },
    {
      Header: "Keyword",
      accessor: 'keyword',
      width: '150',
    },
    {
      Header: "Options",
      width: '50',
      Cell: ({row}) => {
        return(
          <SavedSearchOptions
            id={row.original.id}
            deleteItemCallback={deleteSavedSearchesCallback}
            editItemCallback={editSavedSearchesCallback}
            searchItemCallback={searchSavedSearchesCallback}
          />
        )
      }
    },
  ], [savedSearches, editableRowId])

  const GlobalFilter = ({
                          preGlobalFilteredRows,
                          globalFilter,
                          setGlobalFilter,
                        }) => {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)

    return (
      <span>
      Search:{' '}
        <input
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
    </span>
    )
  }

  const Table = function ({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 10 },
      },
      useFilters,
      useGlobalFilter,
      usePagination
    )

    const [currentPage, setCurrentPage] = useState(pageIndex + 1)

    return (
      <>
        <div className={styles.tableSort}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />

          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>

        <BTable striped bordered hover size="sm" {...getTableProps()} className={styles.modalTable}>
          <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th width={column.width} {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td width={cell.column.width} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
          </tbody>
        </BTable>

        <div className="pagination pagination-sm pagination-wrapper justify-content-start">
          <div className="page-item">
            <button className="btn btn-success btn-sm"
                    onClick={() => {
                      setCurrentPage(currentPage - 1)
                      previousPage()
                    }}
                    disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
          </div>
          <div className="page-item" style={{minWidth: '120px', margin: '0'}}>
            <div className="page-item--control">
              <span className="" >Page </span>
              <input
                className="form-control"
                value={currentPage || ""}
                onChange={e => {
                  setCurrentPage(e.target.value)
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
              />
              <span className="">of {pageOptions.length}</span>
            </div>
          </div>
          <div className="page-item">
            <button className="btn btn-success btn-sm"
                    onClick={() => {
                      setCurrentPage(currentPage + 1)
                      nextPage()
                    }} disabled={!canNextPage}>
              {">>"}
            </button>
          </div>
        </div>
      </>
    )
  }

  useEffect( () => {
    if(showModal) {
      axios.get(
        routes.api.savedSearches(),
        {
          headers: {
            accept: 'application/json'
          }
        }
      )
        .then( success => {
          setSavedSearches(success.data)
        })
        .catch(error => {
          console.log('ERROR', error, error.response)
        })
    }
  }, [showModal])


  return (
    <>
      {showModal && <ReactModal
        ariaHideApp={false}
        isOpen={showModal}
        className={`modal-dialog ${styles.modalWrapper}`}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h3 className="modal-title">Saved Searches</h3>
            <button className={styles.closeBtn} onClick={() => {setShowModal(false)}}>
              <FontAwesomeIcon icon={faXmark} color="grey" />
            </button>
          </div>
          <div className='modal-body'>
            <Table columns={columns} data={savedSearches} />
          </div>
        </div>
      </ReactModal> }
    </>
  );
};

export default SavedSearchModal;
