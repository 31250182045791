import { createSlice } from '@reduxjs/toolkit'

const initialState = {
}

export const searchSlice = createSlice({
  name: "searchSlice",
  initialState,
  reducers: {
    setSearchSlice: (state, action) => {
      return {
        ...action.payload,
      }
    },
    clearSearchSlice: () => {
      return {
        ...initialState
      }
    }
  }
})

export const { setSearchSlice, clearSearchSlice } = searchSlice.actions

export default searchSlice.reducer
