import React, { useState, useEffect } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { html } from "js-beautify";
import ToggleButton from "react-toggle-button";
import InfiniteScroll from "react-infinite-scroll-component";

const htmlStylingOptions = {
  indent_size: 4,
  html: {
    end_with_newline: true
  }
};

function formatHTMLForDiff(htmlStr, textOnly) {
  const formattedHTML = html(htmlStr, htmlStylingOptions);

  if (textOnly) {
    return new DOMParser().parseFromString(formattedHTML, "text/html").body
      .innerText;
  }

  return formattedHTML;
}

export default function PoliciesComparison({from, to, max_page}) {
  const [textOnly, setTextOnly] = useState(true)
  const [onlyDiff, setOnlyDiff] = useState(true)
  const [darkTheme, setDarkTheme] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [comparisonData, setComparisonData] = useState(Array.from({ length: 2 }))
  const [showTopButton, setShowTopButton] = useState(false)


  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 1000) {
        setShowTopButton(true)
      } else {
        setShowTopButton(false)
      }
    })
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const compareStyles = {
    variables: {
      light: {
        codeFoldGutterBackground: "#6F767E",
        codeFoldBackground: "#E2E4E5"
      }
    },
    diffContainer: {
      width: "100%"
    }
  };

  const highlightSyntax = str => (
    <pre
      dangerouslySetInnerHTML={{
        __html: str,
      }}
    />
  );

  const policyFullName = (title, version, active) => {
    let fullName = `${title} Version: ${version}`
    if (active) {
      fullName += ' Active'
    }
    return fullName
  }

  const fromPolicyFullName = policyFullName(from.title, from.version, from.active)
  const toPolicyFullName = policyFullName(to.title, to.version, to.active)


  const viewerHTML = (index) => {
    return (
      <ReactDiffViewer
        oldValue={formatHTMLForDiff(from.content_pages[index]?.text, textOnly)}
        newValue={formatHTMLForDiff(to.content_pages[index]?.text, textOnly)}
        splitView={true}
        showDiffOnly={onlyDiff}
        compareMethod={DiffMethod.WORDS}
        styles={compareStyles}
        leftTitle={<div className="policy-title">{fromPolicyFullName}</div>}
        rightTitle={<div className="policy-title">{toPolicyFullName}</div>}
        useDarkTheme={darkTheme}
        renderContent={highlightSyntax}
      />
    )
  }

  const addMoreData = () => {
    setTimeout(() => {
      setComparisonData(comparisonData.concat(Array.from({ length: 2 })))
      if (comparisonData.length >= max_page) {
        setHasMore(false)
      }
    }, 500);
  }

  const style = {
    border: "1px solid green",
    margin: 6,
    padding: 8
  }

  return(
    <div>
      <br />
      <div className="App compare-wrapper">
        <div className="compare-header">
          <div>
            Text only
            <ToggleButton
              onToggle={(value) => {
                setTextOnly(!value);
              }}
              value={textOnly}
            />
          </div>
          <div>
            Show only differences
            <ToggleButton
              onToggle={(value) => {
                setOnlyDiff(!value);
              }}
              value={onlyDiff}
            />
          </div>
          <div>
            Dark Theme
            <ToggleButton
              onToggle={(value) => {
                setDarkTheme(!value);
              }}
              value={darkTheme}
            />
          </div>
        </div>

        <InfiniteScroll
          dataLength={comparisonData.length}
          next={addMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {comparisonData.map((_i, index) => {
            return (
              <>
                <div className="compare-page-header" style={style}>PAGE {index + 1}</div>
                {viewerHTML(index)}
              </>
            )
          }) }
        </InfiniteScroll>
        {showTopButton && (
          <button onClick={scrollToTop} className="compare-go-btn">
            &#8679;
          </button>
        )}
      </div>
    </div>
  )
}
