import React from 'react';
import { isEmpty } from "lodash";

const PoliciesComparisonV2 = ({ url, error }) => {

  return (
    <div>
      {!isEmpty(url) && (
        <iframe
          src={url}
          style={{width: '100%', border: '1px solid #e0e0e0', height: '600px'}}
          allowFullScreen
        />
      )}

      {!isEmpty(error) && <span>{error}</span>}
    </div>
  );
};

export default PoliciesComparisonV2;
