import React, {useState, useCallback, useEffect} from "react";
import PropTypes from 'prop-types'
import SearchForm from './SearchForm'
import SearchResult from './SearchResult'
import Loader from "../../Loaders/Loader";
import axios from "axios";
import routes from "../../../packs/constants/routes";
import styles from './PolicyWrapper.module.scss'
import _ from 'lodash'
import SavedSearchModal from "../SavedSearchModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { clearSearchSlice, setSearchSlice } from '../../../packs/Reducers/slices/searchSlice'

export default function PolicySearchWrapper({companies, nation_states, categories}){
  const formFilters = useSelector((state) => state.search)
  const dispatch = useDispatch()

  const [showModal, setShowModal] = useState(false)
  const [policies, setPolicies] = useState([])
  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState({
    current_page: 1,
    per_page: 10
  })
  const [sorting, setSorting] = useState({ attribute: '_score', direction: 'desc' })
  const { current_page, per_page } = meta
  // const [formFilters, setFormFilters] = useState({})
  const [tableFilterValue, setTableFilterValue] = useState()
  const [isShowSaveSearchLink, setIsShowSaveSearchLink] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [successfullySave, setSuccessfullySave] = useState(false)
  const [showClearBtn, setShowClearBtn] = useState(false)

  const submitFormValues = useCallback((values) => {
    setTableFilterValue(null)
    setMeta(prevMeta => _.assign(prevMeta, {current_page: 1}))
    // setFormFilters(values)
    dispatch(setSearchSlice(values))
  }, [])

  useEffect(() => {
    if (!_.isEmpty(formFilters, true)) {
      policiesRequest()
    }
  }, [formFilters, current_page, per_page, tableFilterValue, sorting])

  const clearSearch = () => {
    setTableFilterValue(null)
    setPolicies([])
    setMeta({})
    dispatch(clearSearchSlice())
  }

  const policiesRequest = () => {
    setLoading(true)
    let { value, revision_date_from, revision_date_to, company_ids, nation_state_ids, category_ids, archived_results, onco, score } = formFilters

    let data = {
      value: value,
      score: score,
      revision_date_from: revision_date_from && revision_date_from.toISOString(),
      revision_date_to: revision_date_to && revision_date_to.toISOString(),
      page: current_page,
      per_page: per_page,
      table_filter_value: tableFilterValue,
      order_by: `${sorting.attribute}-${sorting.direction}`
    }
    if (company_ids?.length > 0) { _.merge(data, {
      company_ids: company_ids.map(function(item){ return item.value})})
    }
    if (category_ids?.length > 0) { _.merge(data, {
      category_ids: category_ids.map(function(item){ return item.value})})
    }
    if (nation_state_ids?.length > 0) { _.merge(data, {
      nation_state_ids: nation_state_ids.map(function(item){ return item.value})
    })}
    if (!archived_results) { _.merge(data, {
      archived_results: 'false'
    }) }

    if (onco?.value !== '') {
      _.merge(data, {onco: onco?.value})
    }

    axios
      .get(
        routes.api.policySearch(),
        {
          params: data,
          headers: {
            accept: 'application/json'
          }
        }
      )
      .then( success => {
        setPolicies(success.data.policies)
        setMeta(success.data.meta)
        setLoading(false)
        setIsShowSaveSearchLink(true)
        setShowClearBtn(true)
      })
      .catch(error => {
        setLoading(false)
        console.log('ERROR', error, error.response)
      })
  }

  const handleSaveSearch = () => {
    setShowLoader(true)
    setIsShowSaveSearchLink(false)

    let query = {
      revision_date_from: formFilters.revision_date_from || '',
      revision_date_to: formFilters.revision_date_to || '',
      company_ids: formFilters.company_ids || [],
      nation_state_ids: formFilters.nation_state_ids || [],
      archived_results: formFilters.archived_results || false
    }

    if (formFilters.onco !== undefined && formFilters.onco?.value !== '') {
      _.merge(query, {onco: formFilters.onco?.value})
    }

    axios.post(
      routes.api.createSavedSearch(),
      {
        keyword: formFilters.value,
        query: query
      }
    )
      .then(response => {
        setShowLoader(false)
        setSuccessfullySave(true)
      })
      .catch(error => {
        console.log('ERROR', error, error.response)
      })
  }

  useEffect(() => {
    if(successfullySave){
      setTimeout(() => {
        setSuccessfullySave(false); // count is 0 here
      }, 3000);
    }
  })

  return (
    <div className="row">
      {showModal && <SavedSearchModal showModal={showModal} setShowModal={setShowModal}/>}

      <aside className="col-xs-12 col-md-3">
        <div className={styles.sidebarTitle}>
          <h4>Search</h4>
          {showClearBtn && <button className={styles.clearBtn} onClick={() => clearSearch()}>CLEAR</button> }
        </div>

        <div className={styles.saveSearch}>
          <div><span onClick={() => setShowModal(true)}>Open Saved Searches</span></div>
          <div>
            {isShowSaveSearchLink && <span onClick={() => handleSaveSearch()}>Save Search</span>}
            {showLoader && <FontAwesomeIcon icon={faSpinner} color="green" className={styles.loadingSvg}/> }
            {successfullySave && <span className={styles.successText}><FontAwesomeIcon icon={faCheck} /> success</span>}
          </div>
        </div>
        <SearchForm
          companies={companies}
          nation_states={nation_states}
          categories={categories}
          setPolicies={setPolicies}
          setMeta={setMeta}
          setLoadingCallback={setLoading}
          submitDisabled={loading}
          submitFormValues={submitFormValues}
        />
      </aside>

      <div className="col-xs-12 col-md-9">
        <div id="search_result" className="search_result">
          { loading ? <Loader /> : <SearchResult policies={policies} meta={meta} setMeta={setMeta} tableFilterValue={tableFilterValue} setTableFilterValue={setTableFilterValue} sorting={sorting} setSorting={setSorting}/> }
        </div>
      </div>
    </div>
  )
}
