import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrash, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import styles from '../Styles.module.scss'
import axios from "axios";
import routes from "../../../../packs/constants/routes";
import ReactTooltip from 'react-tooltip'

const SavedSearchOptions = ({ id, deleteItemCallback, editItemCallback, searchItemCallback }) => {
  const handleSearch = () => {
    searchItemCallback(id)
  }

  const handleEdit = () => {
    editItemCallback(id)
  }

  const handleDelete = () => {
    if (confirm('Are you sure?')) {
      axios.delete(
        routes.api.deleteSavedSearch({id}),
        {
          headers: {
            accept: 'application/json'
          }
        }
      )
        .then(response => {
          if (response.status === 204) {
            deleteItemCallback(id)
          }
        })
        .catch(error => {
          console.log('ERROR', error, error.response)
        })
    }
  }

  return (
    <div className={styles.optionsWrapper}>
      <div>
        <span data-tip data-for="searchIcon" onClick={() => handleSearch()} >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
        <ReactTooltip id="searchIcon" place="top" effect="solid">Search policy</ReactTooltip>
      </div>

      <div>
        <span data-tip data-for="editIcon" onClick={() => handleEdit()} >
          <FontAwesomeIcon icon={faPenToSquare} />
        </span>
        <ReactTooltip id="editIcon" place="top" effect="solid">Edit saved search title</ReactTooltip>
      </div>

      <div>
        <span data-tip data-for="deleteIcon" onClick={() => handleDelete()} >
          <FontAwesomeIcon icon={faTrash} />
        </span>
        <ReactTooltip id="deleteIcon" place="top" effect="solid">Delete saved search</ReactTooltip>
      </div>
    </div>
  );
};

export default SavedSearchOptions;
