import React, { useState } from 'react'
import ReactModal from 'react-modal'
import styles from './index.module.scss'

export default function IconModal(props) {
    const { title, documents, category } = props
    const [showModal, setShowModal] = useState(false)

    const disabledClass = (
        documents ? styles.enabledLink : styles.disabledLink
    )

    let docs = documents || []

    return (<div>
            <a className={disabledClass} onClick={() => {setShowModal(true)}}><i className="fas fa-external-link-alt" ></i></a>
            {showModal && <ReactModal
                    isOpen={showModal}
                    className={'modal-dialog'}
                    onRequestClose={() => {setShowModal(false)}}
                    shouldCloseOnOverlayClick={true}
                    ariaHideApp={false}
                    style={{overlay: {zIndex: 100}}}
                >
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h3 className="modal-title">{category}</h3>
                    </div>
                    <div className='modal-body'>
                        <div className='form-horizontal'>
                            <div className='form-group'>
                                <label className="control-label col-md-4">Payer Name</label>
                                <div className='col-md-8'>
                                    <label className="control-label">{title}</label>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label className={`control-label col-md-4`}>Payer Resource Link</label>
                                <div className="control-label col-md-8">
                                    {docs.map( item => {
                                        return (
                                            <p><a href={item.url} target='_blank'>{item.title}</a></p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button onClick={() => {setShowModal(false)}}>Close</button>
                    </div>
                </div>
                </ReactModal>}
        </div>)
}
