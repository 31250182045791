import React, { useEffect, useState } from 'react'
import { Form, Field, FormSpy } from 'react-final-form'
import Select from "react-select"
import {Popover, OverlayTrigger, Tooltip} from "react-bootstrap"
import RenderDatePicker from "../../utils/DatePicker";
import _, { isEmpty } from 'lodash'
import FilterModal from "../FilterModal/FilterModal"
require('react-datepicker/dist/react-datepicker.css')
import { useSelector } from 'react-redux'

export default function SearchForm({companies, nation_states, categories, submitDisabled, submitFormValues}) {
  const formValues = useSelector((state) => state.search)

  const companiesSelectOptions = companies.map(item => ({value: item.id, label: item.title})).sort((a, b) => {
      return a.label.localeCompare(b.label)
  })
  const nationStatesSelectOptions = nation_states.map(item => ({value: item.id, label: item.title})).sort((a, b) => {
      return a.label === 'National' ? -1 : b.label === 'National' ? 1 : a.label.localeCompare(b.label)
  })
  const categoriesSelectOptions = categories.map(item => ({value: item.id, label: item.name})).sort((a, b) => {
    return a.label.localeCompare(b.label)
  })
  const oncoSelectOptions = [
    {value: '', label: 'All'}, { value: true, label: 'TRUE'}, { value: false, label: 'FALSE'}
  ]
  const [showModal, setShowModal] = useState(false)

  const onSubmit = async (values) => {
    if (isEmpty(values))
      setShowModal(true)
    else
      submitFormValues(values)
  }

  const popover = (
    <Popover id="popover-basic" className="popover-advanced">
      <Popover.Header as="h3">Multiple Keywords</Popover.Header>
      <Popover.Body>
        <p>
          Type the keywords you would like to return in the search. The results will only be those policies that
          include ANY of the keywords you selected. This is equivalent to the words being separated by "OR"s. An example
          of a multiple keyword search would be:
          <br/>
          63650 63685
          <br/>
          63650 OR 63685
          <br/>
          The two searches above are treated identically.
        </p>
      </Popover.Body>
      <Popover.Header as="h3">"PHRASES" in Quotations</Popover.Header>
      <Popover.Body>
        <p>
          If there is a particular phrase, or exact set of keywords, that you are looking for, enter it in the search
          field with double quotes around the words. For example:
          <br/>
          "Spinal Cord Stim"
          <br/>
          This search would return only policies that have the phrase "Spinal Cord Stim". The last word is treated as a wild
          card word. So the results could include Spinal Cord Stimulator, Spinal Cord Stimulation, or any other results
          where the first 4 letters of the last word are "stim".
        </p>
      </Popover.Body>
      <Popover.Header as="h3">Use "AND" for Combination of Keywords</Popover.Header>
      <Popover.Body>
        <p>
          To find the policies with only certain keywords, type the keywords you are searching for with the term "AND"
          in between. This will only return policies where all of the words exist somewhere in the policies.
          The words may appear in any order throughout the policies. For example:
          <br/>
          63650 AND 63685
          <br/>
          With this search any policy returned would have both CPT codes present in the policy.
        </p>
      </Popover.Body>

    </Popover>
  );

  const relevancePopover = (
    <Popover id="popover-basic" className="popover-advanced">
      <Popover.Header as="h3">What is relevance</Popover.Header>
      <Popover.Body>
        <p>
          It's a minimal score filter for search result's. All results below this value will be excluded from search.
        </p>
      </Popover.Body>
      <Popover.Header as="h3">Factor's affecting relevance</Popover.Header>
      <Popover.Body>
        <p>
          1. Term Frequency (TF): The number of times a term appears in a document.
          A higher frequency indicates a stronger relationship between the term and the document.
        </p>
        <p>
          2. Inverse Document Frequency (IDF): Measures the importance of a term across all documents in the index.
          A term that appears in fewer documents will have a higher IDF, signifying its importance.
        </p>
        <p>
          3. Field Length Norm: The length of the field in which the term appears.
          Shorter fields with matching terms are considered more relevant, as the term occupies a larger portion of the field.
        </p>
        <p>
          4. Query-time Boosting: Allows to assign a boost factor to specific fields or terms during query execution.
          This can be useful for emphasizing the importance of certain fields or terms in the search results.
          (current boost pattern: title^10 file_content^2 company_name nation_state_name)
        </p>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {showModal && <FilterModal showModal={showModal} closeCallback={setShowModal}/>}
      <Form
        onSubmit={onSubmit}
        initialValues={formValues}
        render={({ handleSubmit, form,  values, submitting}) => {
          return (
            <form className="search_form"  onSubmit={handleSubmit} >
              <div className="mb-3">
                <label htmlFor="keywordsId" className="form-label">
                  Keyword(s)*

                  <OverlayTrigger key='top' placement='right' overlay={popover} >
                    <div className="advanced-search-icon"><i className="far fa-question-circle"></i></div>
                  </OverlayTrigger>

                </label>
                <Field name="value">
                  {({ input }) => (
                    <input {...input} className="form-control" id="keywordsId" />
                  )}
                </Field>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <Field name="archived_results" component="input" type="checkbox" >
                    {({ input }) => (
                      <input {...input} className="form-check-input" id="archivedResults"  />
                    )}
                  </Field>
                  <label htmlFor="archivedResults" className="form-check-label">Show archived results</label>
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="score" className="form-label">
                  Relevance*
                  <OverlayTrigger key='top' placement='right' overlay={relevancePopover} >
                    <div className="advanced-search-icon"><i className="far fa-question-circle"></i></div>
                  </OverlayTrigger>
                </label>
                <Field
                  defaultValue={10}
                  component="input"
                  name={"score"}
                  type={"number"}
                  step={'0.01'}
                >
                  {({ input }) => (
                    <input {...input} className="form-control" id="score" />
                  )}
                </Field>
              </div>
              <div className="mb-3">
                <label htmlFor="company_id" className="form-label">Payer(s)</label>
                <Field
                  component="select"
                  name={"company_ids"}
                >
                  {({ input }) => (
                    <Select
                      {...input}
                      autosize={false}
                      isMulti
                      id={"company_id"}
                      options={companiesSelectOptions}
                      placeholder={"Select Payer"}
                    />
                  )
                  }
                </Field>
              </div>

              <div className="mb-3">
                <label htmlFor="nation_state" className="form-label">State(s)</label>
                <Field
                  component="select"
                  name={"nation_state_ids"}
                >
                  {({ input }) => (
                    <Select
                      {...input}
                      autosize={false}
                      isMulti
                      id={"nation_state"}
                      options={nationStatesSelectOptions}
                      placeholder={"Select State"}
                    />
                  )
                  }
                </Field>
              </div>

              <div className="mb-3">
                <label htmlFor="category_id" className="form-label">Category</label>
                <Field
                  component="select"
                  name={"category_ids"}
                >
                  {({ input }) => (
                    <Select
                      {...input}
                      autosize={false}
                      isMulti
                      id={"category_id"}
                      options={categoriesSelectOptions}
                      placeholder={"Select Category"}
                    />
                  )
                  }
                </Field>
              </div>

              <div className="mb-3">
                <label htmlFor="onco" className="form-label">Oncology</label>
                <Field
                  component="select"
                  name={"onco"}
                >
                  {({ input }) => (
                    <Select
                      {...input}
                      autosize={false}
                      id={"onco"}
                      options={oncoSelectOptions}
                      placeholder={"Cancer Type"}
                    />
                  )
                  }
                </Field>
              </div>

              <div className="mb-3">
                <label htmlFor="keywordsId" className="form-label">Revision Date(s)</label>
                <Field
                  name="revision_date_from"
                  component={RenderDatePicker}
                  placeholderText="Select revision date from.."
                />
                <Field
                  name="revision_date_to"
                  component={RenderDatePicker}
                  placeholderText="Select revision date to.."
                />
              </div>
              <div className="search_form__notice"><span>*</span> Required</div>

              <button type="submit" className="btn btn-success btn-search " disabled={submitDisabled}>Search</button>
            </form>
          )}}
      />
    </>

  )
}
