import React from "react"
import PropTypes from "prop-types"
import PolicySearchWrapper from "./Policy/Search/PolicySearchWrapper"
import { Provider } from 'react-redux'
import store from '../packs/Reducers/index'

class PolicySearch extends React.Component {
  render () {
    return (
      <Provider store={store}>
        <PolicySearchWrapper {...this.props} />
      </Provider>
    );
  }
}

export default PolicySearch
